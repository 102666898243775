import React, { useRef, useState } from 'react'
import { graphql } from 'gatsby'
import { useReactToPrint } from 'react-to-print'

import { Seo } from '../../components/base'
import {
  CaloriesIcon,
  PreparationTimeIcon,
  BlockContent,
  SocialSharing,
  PrintIcon,
  BioPortionAmount,
  BioIngredientsList,
  BioReferencedRecipes,
  BioRecipeInformationItem
} from '../../components/shared'
import { SinglePage } from '../../templates/SinglePage'
import { RecipeToPrint } from '../../components/recipes/RecipeToPrint'
import { getPageBuilderComponents } from '../../components/base/PageBuilderComponents'
import { getLocaleMarket } from '../../utils/getLocaleMarket'
import { useShoppingListStore } from '../../utils/shoppingListStore'

import * as styles from '../../css/organism/bioSanityRecipeSlug_current.module.scss'

const RecipePage = ({ data, location, pageContext }) => {
  if (!data) return
  const {
    title,
    subtitle,
    seo,
    image,
    imageMobile,
    fullWidthHeader,
    caloricValue,
    preparationTime,
    ingredientsList,
    _rawPreparation,
    vegan,
    vegetarian,
    lactosefree,
    glutenfree,
    portionAmount,
    mainIngredient,
    pageBuilder
  } = data.sanityRecipe
  const [portionCounter, setPortionCounter] = useState(Number(portionAmount))
  const [ingredientAmount, setIngredientAmount] = useState()
  const [ingredientsForShoppingList, setIngredientsForShoppingList] = useState(
    []
  )
  // for later use -> see store
  //const { addItemFromRecipe } = useShoppingListStore(state => state)

  let myMarket = getLocaleMarket()
  const languages = { pageContext }
  let ingredients = []
  let preparation = []

  const ingredientsCategories = [
    { title: 'Sonstiges', value: 0 },
    { title: 'Obst & Gemüse', value: 1 },
    { title: 'Speisekammer', value: 2 },
    { title: 'Zum Kühlen', value: 3 },
    { title: 'Getränke', value: 4 }
  ]

  _rawPreparation.forEach(key => {
    preparation += key.children[0].text
  })
  preparation = preparation.split('.')

  const preparationTimeNumber = preparationTime?.replace(/\D+/g, '')
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  //push ingredients for SEO and ShoppingList
  ingredientsList.forEach(items =>
    items.ingredientsItem.forEach(item => {
      ingredients.push(item?.ingredient?.title)
      ingredientsCategories.forEach(cat => {
        let amount = (item.quantity / portionAmount) * portionCounter

        if (cat.value === item?.ingredient?.ingredientsCategory) {
          ingredientsForShoppingList.push({
            title: `${
              amount > 1 && item?.ingredient.plural
                ? item?.ingredient?.plural
                : item?.ingredient?.title
            }`,
            category: cat.title,
            recipe: title
          })
        }
      })
    })
  )

  //for future usage: push ingredients to shoppinglist
  // const uniqueIngredientsForShoppingList = [
  //   ...new Map(
  //     ingredientsForShoppingList.map(ingredient => [
  //       ingredient.title,
  //       ingredient
  //     ])
  //   ).values()
  // ]

  const uniqueIngredients = [
    ...new Set(ingredients.map(ingredient => ingredient))
  ]

  return (
    <SinglePage
      noHeroTitle
      heroImage={image}
      heroImageMobile={imageMobile}
      fullWidthHeader={fullWidthHeader}
      heroTitle
      translations={languages}>
      <Seo
        title={seo?.metaTitle}
        description={seo?.metaDescription}
        image={seo?.ogImage?.asset?.url}
        recipe={{
          title,
          preparationTimeNumber,
          portionAmount,
          caloricValue,
          uniqueIngredients,
          preparation,
          image
        }}
      />
      <section className="md:mx-auto singlePageSection">
        <div className="mb-13">
          <h1 className="text-center" data-cy="recipe-title">
            {title}
          </h1>
          {subtitle && (
            <p
              className="text-lg md:text-xl text-center uppercase"
              style={{ lineHeight: '1.1666' }}>
              {subtitle}
            </p>
          )}
        </div>

        <div className={styles.bioCurrentRecipe}>
          <section className={styles.__header}>
            <ul>
              <BioRecipeInformationItem
                title="Zubereitungszeit"
                icon={<PreparationTimeIcon />}
                text={preparationTime}
              />
              <BioRecipeInformationItem
                title="Brennwert"
                icon={<CaloriesIcon />}
                text={caloricValue}
              />
              <BioPortionAmount
                portionCounter={portionCounter}
                setPortionCounter={setPortionCounter}
              />
            </ul>
            <button
              className={styles.__print}
              onClick={handlePrint}
              data-cy="recipe-print">
              <span>
                <PrintIcon />
              </span>
              <p>Rezept drucken</p>
              <RecipeToPrint
                data={data.sanityRecipe}
                portionCounter={portionCounter}
                portionAmount={portionAmount}
                ref={componentRef}
              />
            </button>
          </section>
          {/* <div className="flex justify-end py-8 ">
          <button
            className="text-md font-bold hover:text-green"
            onClick={() => addItemFromRecipe(uniqueIngredientsForShoppingList)}>
            Zur Einkaufsleiste hinzufügen
          </button>
        </div> */}
          <section className={styles.__content}>
            <BioIngredientsList
              ingredientsList={ingredientsList}
              vegan={vegan}
              vegetarian={vegetarian}
              lactosefree={lactosefree}
              glutenfree={glutenfree}
              portionAmount={portionAmount}
              portionCounter={portionCounter}
              setIngredientAmount={setIngredientAmount}
              alignLeft={true}
            />
            <article className={styles.__preparation} data-cy="preparation">
              <h3>Zubereitung</h3>
              <BlockContent data={_rawPreparation} />
            </article>
          </section>
          {pageBuilder &&
            pageBuilder.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  {getPageBuilderComponents(item, myMarket)}
                </React.Fragment>
              )
            })}
        </div>
      </section>
      <BioReferencedRecipes
        referenceString={mainIngredient}
        recipes={data.allSanityRecipe.nodes}
        title={title}
      />
      <SocialSharing link={location} />
    </SinglePage>
  )
}

export default RecipePage

export const query = graphql`
  query ($id: String, $language: String! = "de_de") {
    sanityRecipe(_id: { eq: $id }) {
      title
      subtitle
      seo {
        ...SeoQuery
      }
      image {
        ...ImageObjectQuery
      }
      imageMobile {
        ...ImageQuery
      }
      previewImage {
        ...ImageObjectQuery
      }
      caloricValue
      fullWidthHeader
      preparationTime
      portionAmount
      ingredientsList {
        headline
        ingredientsItem {
          quantity
          customUnit
          variety
          ingredient {
            ingredientsCategory
            title
            plural
            isSpice
          }
          unit {
            unit
          }
        }
      }
      _rawPreparation
      vegan
      vegetarian
      lactosefree
      glutenfree
      mainIngredient {
        ... on SanityBioStandardIngredients {
          title
        }
      }
      ...RecipePageBuilderElements
    }
    allSanityRecipe(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        title
        preparationTime
        vegan
        vegetarian
        lactosefree
        glutenfree
        mainIngredient {
          ... on SanityBioStandardIngredients {
            title
          }
        }
        basePath
        slug: slug {
          current
        }
        previewImage {
          alt
          image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 600)
            }
          }
        }
      }
    }
  }
`
